import * as analytics from "./analytics/index";

var oldScrollY = window.scrollY;
$(window).scroll(function() {
  var newScrollY = window.scrollY;
  if (newScrollY === 0 || newScrollY < oldScrollY) {
    $(".header").css("top", "0");
    $(".header").css("opacity", "1.0");
  }
  else if (newScrollY > oldScrollY && newScrollY >= 0) {
    $(".header").css("top", "-75px");
    $(".header").css("opacity", "0");
  }
  oldScrollY = newScrollY;
});

analytics.initialize();