
function addAnalytics() {
  $(".header .ctabutton").click(function() {
    fbq('track', 'Contact', {
      contact_target: 'WhatsApp',
      contact_section: 'Header'
    });
    gtag('event', 'click', { 'event_category': 'ContactButton', 'event_label': 'Header_WhatsApp', 'value': 1 });
  });

  $(".contact .whatsapp").click(function() {
    fbq('track', 'Contact', {
      contact_target: 'WhatsApp',
      contact_section: 'Footer'
    });
    gtag('event', 'click', { 'event_category': 'ContactButton', 'event_label': 'Footer_WhatsApp', 'value': 1 });
  });

  $(".contact .socialicons a[data-type=\"email\"]").click(function() {
    fbq('track', 'Contact', {
      contact_target: 'Email',
      contact_section: 'Footer'
    });
    gtag('event', 'click', { 'event_category': 'SocialButton', 'event_label': 'Email', 'value': 1 });
  });

  $(".contact .socialicons a[data-type=\"fb\"]").click(function() {
    fbq('track', 'ViewContent', {
      content_target: 'Facebook'
    });
    gtag('event', 'click', { 'event_category': 'SocialButton', 'event_label': 'Facebook', 'value': 1 });
  });

  $(".contact .socialicons a[data-type=\"insta\"]").click(function() {
    fbq('track', 'ViewContent', {
      content_target: 'Instagram'
    });
    gtag('event', 'click', { 'event_category': 'SocialButton', 'event_label': 'Instagram', 'value': 1 });
  });
}

export function initialize() {
  addAnalytics();
}